import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
import { formatDistanceToNow } from "date-fns";
import Loader from "../Loader/Loader";

const NewsPage = () => {
  const [news, setNews] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true); // Loading state
  const itemsPerPage = 6; // Displaying 6 items per page
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init({
      easing: "ease-in-out",
      once: false,
      mirror: true,
      duration: 2000,
    });
  }, []);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.get(
          "https://kirofficee.api.aakirsub.com/api/news"
        );
        // Sort news by date in descending order (newest first)
        const sortedNews = response.data.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        );
        setNews(sortedNews);
        setLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        console.error("Error fetching news:", error);
        setLoading(false); // Set loading to false even if there is an error
      }
    };

    fetchNews();
  }, []); // Empty dependency array ensures this effect runs only once on component mount

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentNews = news.slice(indexOfFirstItem, indexOfLastItem);

  // Function to truncate text
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  // Change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleReadMore = (item) => {
    navigate(`/newsdetail/${item._id}`, {
      state: {
        imageURL: item.imageURL,
        title: item.title,
        description: item.text,
      },
    });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "Invalid date";
    }
    return formatDistanceToNow(date, { addSuffix: true });
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-8">
      <h2
        className="text-6xl font-bold mb-6 text-center text-blue-800"
        data-aos="fade-down"
      >
        {t("Latest News")}
      </h2>
      {loading ? (
        <div className="w-full min-h-screen">
          <Loader />
        </div>
      ) : (
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-3">
          {currentNews.length === 0 ? (
            <p>No news available, you are offline!</p>
          ) : (
            currentNews.map((item, index) => (
              <div
                key={item._id}
                className="border rounded-md shadow-md hover:shadow-lg transition duration-300"
                data-aos="fade-up"
                data-aos-delay={`${index * 100}`}
              >
                <img
                  src={`https://kirofficee.api.aakirsub.com/${item.imageURL}`}
                  alt={item.title}
                  className="w-full h-60 object-fill rounded-t-md"
                />
                <div className="p-4">
                  <h3 className="text-xl font-semibold mb-2">
                    {truncateText(item.title, 60)}
                  </h3>
                  <p className="text-gray-600 mb-2">
                    {truncateText(item.text, 300)}
                  </p>
                  <button
                    onClick={() => handleReadMore(item)}
                    className="block px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 text-center"
                  >
                    Read More
                  </button>
                  <p className="text-gray-500 mt-2">{formatDate(item.date)}</p>
                </div>
              </div>
            ))
          )}
        </div>
      )}
      {/* Pagination */}
      {!loading && news.length > itemsPerPage && (
        <div className="mt-6 flex justify-center">
          {[...Array(Math.ceil(news.length / itemsPerPage)).keys()].map(
            (pageNumber) => (
              <button
                key={pageNumber + 1}
                onClick={() => paginate(pageNumber + 1)}
                className={`px-3 py-1 hover:text-blue-600 ${
                  currentPage === pageNumber + 1
                    ? "font-bold bg-yellow-500 text-white rounded-lg m-2"
                    : ""
                }`}
              >
                {pageNumber + 1}
              </button>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default NewsPage;
