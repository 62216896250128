import React, { useEffect, useState } from "react";
import { Image, Pagination } from "antd";
import "aos/dist/aos.css"; // Import AOS CSS
import AOS from "aos";
import axios from "axios";
import HammerBanner from "../assets/images/tradeb.jpg";
import { useTranslation } from "react-i18next";

function Gallery() {
  const { t, i18n } = useTranslation();
  const [images, setImages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15; // 3 rows per page, 3 columns per row

  useEffect(() => {
    AOS.init({ duration: 2000 }); // Initialize AOS

    // Fetch images from the backend
    const fetchImages = async () => {
      try {
        const response = await axios.get("https://kirofficee.api.aakirsub.com/api/images");
        setImages(response.data);
        console.log(response.data); // Log the images data to verify
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    fetchImages();
  }, []);

  // Calculate the images to display for the current page
  const indexOfLastImage = currentPage * itemsPerPage;
  const indexOfFirstImage = indexOfLastImage - itemsPerPage;
  const currentImages = images.slice(indexOfFirstImage, indexOfLastImage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  if (images.length === 0) {
    return (
      <div className="container mx-auto px-5 py-2 lg:px-32 lg:pt-12">
        <div className="-m-1 flex flex-wrap md:-m-2">
          <div className="flex w-1/3 flex-wrap">
            <div className="w-full p-1 md:p-2">
              <img
                alt="gallery"
                className="block h-full w-full rounded-lg object-cover object-center"
                src="https://tecdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(73).webp"
              />
            </div>
          </div>
          <div className="flex w-1/3 flex-wrap">
            <div className="w-full p-1 md:p-2">
              <img
                alt="gallery"
                className="block h-full w-full rounded-lg object-cover object-center"
                src="https://tecdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(74).webp"
              />
            </div>
          </div>
          <div className="flex w-1/3 flex-wrap">
            <div className="w-full p-1 md:p-2">
              <img
                alt="gallery"
                className="block h-full w-full rounded-lg object-cover object-center"
                src="https://tecdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(75).webp"
              />
            </div>
          </div>
          <div className="flex w-1/3 flex-wrap">
            <div className="w-full p-1 md:p-2">
              <img
                alt="gallery"
                className="block h-full w-full rounded-lg object-cover object-center"
                src="https://tecdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(70).webp"
              />
            </div>
          </div>
          <div className="flex w-1/3 flex-wrap">
            <div className="w-full p-1 md:p-2">
              <img
                alt="gallery"
                className="block h-full w-full rounded-lg object-cover object-center"
                src="https://tecdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(76).webp"
              />
            </div>
          </div>
          <div className="flex w-1/3 flex-wrap">
            <div className="w-full p-1 md:p-2">
              <img
                alt="gallery"
                className="block h-full w-full rounded-lg object-cover object-center"
                src="https://tecdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(72).webp"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="relative shadow-2xl mb-8">
        <img
          src={HammerBanner}
          className="w-full h-40 object-cover"
          alt="Banner"
        />
        <h1 className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 text-white text-4xl md:text-6xl font-bold">
          {t('Image Gallery')}
        </h1>
      </div>
      <div className="container mx-auto px-5 py-2 lg:px-32 lg:pt-12">
        <div className="-m-1 flex flex-wrap md:-m-2">
          {currentImages.map((image, index) => (
            <div
              key={index}
              className="w-full sm:w-1/2 md:w-1/3 p-1 md:p-2"
              data-aos="fade-up" // Define AOS animation type here
              data-aos-delay={`${index * 100}`} // Adjust delay for each image
            >
              <div className="w-full">
                <Image
                  alt={`gallery-${index + 1}`}
                  className="block w-full h-full object-fill  rounded-lg"
                  src={`https://kirofficee.api.aakirsub.com${image.imageUrl}`} // Ensure the full URL is correct
                  style={{ width: "100%", height: "300px" }} // Set fixed height for all images
                />
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-center mt-4">
          <Pagination
            current={currentPage}
            pageSize={itemsPerPage}
            total={images.length}
            onChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
}

export default Gallery;
